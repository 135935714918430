import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.component"
import Header from "../components/header.component"
import { Card } from "../components/card.blog.component"
import { linkFragment } from "../link-resolver"
import { RichText } from "../components/common/rich-text.component"
import { PlainStructuredText } from "../components/common/plain-structured-text.component"
import { Pagination } from "../components/common/pagination.component"
import SEO from "../components/common/seo.component"

const BlogCategory = ({ uri, data, pageContext }) => {
  const categories = data.prismic.allBlog_categorys.edges
  const { posts, uid, currentPage, numPages } = pageContext
  const category = data.prismic.blog_category

  if (category) {
    return (
      <Layout>
        <SEO
          title={`ANKO Blog | ${category.page_title || category.title[0].text}`}
          description={category.description || category.text}
          keywords={category.keywords}
          image={category.preview_image}
          lang={category._meta.lang}
          canonical={uri}
        />
        <div className="container">
          <Header theme="light" />
        </div>
        <div className="container">
          <nav className="nav blog-category-nav">
            <Link className="nav-link" activeClassName={"active"} to={"/blog"}>
              Last Posts
            </Link>
            {categories.map(item => {
              const category = item
              return (
                <Link
                  className="nav-link"
                  to={`/blog/${category.node._meta.uid}`}
                >
                  <PlainStructuredText structuredText={category.node.title} />
                </Link>
              )
            })}
          </nav>
          <div className="my-5 mt-1">
            <RichText className="text-dark-blue" render={category.title} />
          </div>
          <div className="container-blog-list mx-auto">
            <div className="row mb-5">
              {posts.map(item => {
                return <Card item={item} />
              })}
            </div>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          numPages={numPages}
          path={`/blog/${category._meta.uid}`}
        />
        {/* <Slices body={page.node.body}/>*/}
      </Layout>
    )
  }
  return null
}

BlogCategory.fragments = [linkFragment]

export default BlogCategory

export const query = graphql`
  query blogCategoryQuery($uid: String!) {
    prismic {
      blog_category(uid: $uid, lang: "en-us") {
        title
        _meta {
          uid
          type
          lang
        }
      }
      allBlog_categorys {
        edges {
          node {
            title
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
    }
  }
`
